const config = {
    firebase: {
      apiKey: 'AIzaSyA7TlGLPbs9dHZneZCbwerNCEy7inRujV8',
      authDomain: 'react-e6d9d.firebaseapp.com',
      projectId: 'react-e6d9d',
      storageBucket: 'react-e6d9d.appspot.com',
      messagingSenderId: '942904356299',
      appId: '1:942904356299:web:8b0905f7f1597fb6e71a4e',
    },
    emailjs: {
      userId: 'EwyOVxRFbXK81rLyk',
      serviceId: 'service_2h9seaq',
      templateId: 'template_dbtbnms',
    },
  };
  
  export default config;
  